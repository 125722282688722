<template>
  <div class="row m-0 abs">
    <div class="col-12">
      <div class="row">
        <div class="col-12 bold smallheading my-4">
          Add General Liability
        </div>
        <div class="col-12 col-md-6 mb-2">
          <TextField
            type="text"
            color="gold"
            placeholder="Description"
            v-model="basic.description"
            id="description"
            label="Description:"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <SelectAll
            v-model="basic.type"
            id="type"
            :options="basicLiabilityTypes"
            displayValue="description"
            placeholder="Basic Type"
            color="gold"
            label="Select Type Of Liability:"
            width="full"
            :goUp="true"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <CurrencyField
            type="text"
            placeholder="0.00"
            id="amount"
            color="gold"
            v-model="basic.total"
            :currencySymbol="defaultCurrency"
            label="Enter Amount:"
          />
        </div>
        <div class="col-12 bold smallheading my-4">
          Total: {{defaultCurrency}}{{parseFloat(Math.round((calculatedAmount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-12 my-4" v-if="isEdit">
          <Button color="green" btnText="Update" icon="arrow" @buttonClicked="update" class="mb-1" >
            <IconArrowForward color="gold" />
          </Button>
          <Button color="transparent" btnText="Cancel" @buttonClicked="cancel" class="mb-1 ms-3" >
          </Button>
        </div>
        <div class="col-12 my-4" v-else>
          <Button color="green" btnText="Save" icon="arrow" @buttonClicked="save" class="mb-1" :disabled="basic.total < 0.01" >
            <IconArrowForward color="gold" />
          </Button>
        </div>
      </div>
      <div class="row" v-if="basicLiabilities.length > 0">
        <div class="col-12 bold smallheading my-4">
          General Liabilities
        </div>
      </div>
      <div class="row py-2 border-bottom align-items-center" v-for="(liability, key) in basicLiabilities" :key="key">
        <div class="col-12">
          <div class="row d-none d-md-flex align-items-center">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{liability.description}}
                </div>
                <div class="col-12 font12">
                  {{ displayDescription(liability.zakaatBasicLiabilityType) }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" btnText="EDIT" icon="arrow" size="xsmall" @buttonClicked="editIndividual(key, liability)">
                <IconEdit size="size16" />
              </Button>
            </div>
            <div class="col-auto text-end bold">
              {{defaultCurrency}}{{parseFloat(Math.round((liability.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="small" @buttonClicked="showDelete(key, liability)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
          <div class="row d-md-none">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{liability.description}}
                </div>
                <div class="col-12 font12">
                  {{ displayDescription(liability.zakaatBasicLiabilityType) }}
                </div>
                <div class="col-12 bold mt-2">
                  {{defaultCurrency}}{{parseFloat(Math.round((liability.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="editIndividual(key, liability)">
                <IconEdit />
              </Button>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="showDelete(key, liability)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Alert v-if="isDelete" title="Are you sure you want to delete this general liability?" :message="basic.description" @close="closeDelete" button1="Delete" button2="Cancel" align="center" @doAction2="closeDelete" @doAction1="deleteItem" key="alert"></Alert>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Alert: defineAsyncComponent(() => import('../../../components/Alert.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    'ios-trash-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-trash.vue'))
  },
  name: 'AddGeneral',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      basic: {
        description: '',
        type: {},
        total: null
      },
      isEdit: false,
      isDelete: false,
      currentKey: null,
      basicLiabilityTypes: [{
        value: 'RENT',
        description: 'Rent'
      }, {
        value: 'RATES_TAXES_WATER_ELECTRICITY',
        description: 'Rates, Taxes, Water & Electricity'
      }, {
        value: 'GROCERIES_AND_CLOTHING',
        description: 'Groceries & Clothing'
      }, {
        value: 'DOMESTIC_WAGES',
        description: 'Domestic Wages'
      }, {
        value: 'TELEPHONE_AND_CELLPHONE',
        description: 'Telephone & Cellphone'
      }, {
        value: 'FUEL_AND_MAINTENANCE',
        description: 'Fuel & Maintenance'
      }, {
        value: 'INSURANCE',
        description: 'Insurance'
      }, {
        value: 'EDUCATION_AND_SCHOOL_FEES',
        description: 'Education & School Fees'
      }, {
        value: 'MEDICAL_EXPENSES',
        description: 'Medical Expenses'
      }, {
        value: 'GOLD_AMOUNT_OWED',
        description: 'Gold Amount Owed'
      }, {
        value: 'SILVER_AMOUNT_OWED',
        description: 'Silver Amount Owed'
      }, {
        value: 'MONEY_YOU_OWE',
        description: 'Money You Owe'
      }, {
        value: 'BUSINESS',
        description: 'Business'
      }, {
        value: 'INVESTMENTS',
        description: 'Investments'
      }, {
        value: 'POLICIES',
        description: 'Policies'
      }, {
        value: 'PARTNERSHIP',
        description: 'Partnership'
      }, {
        value: 'OVERDRAFT_AT_BANK',
        description: 'Overdraft at Bank'
      }, {
        value: 'CREDIT_CARDS_IN_TOTAL',
        description: 'Credit Cards in Total'
      }, {
        value: 'PERSONAL_LOANS',
        description: 'Personal Loans'
      }, {
        value: 'STUDENT_LOANS',
        description: 'Student Loans'
      }, {
        value: 'FURNITURE_ACCOUNTS',
        description: 'Furniture Accounts'
      }, {
        value: 'OTHER',
        description: 'Other'
      }]
    }
  },
  computed: {
    ...mapGetters([
      'basicLiabilities'
    ]),
    calculatedAmount () {
      if (this.basic.total) {
        return parseFloat(this.basic.total).toFixed(2)
      }
      return (0).toFixed(2)
    }
  },
  methods: {
    ...mapActions([
      'addToGeneral',
      'editGeneral',
      'deleteGeneralItem',
      'clearGeneral'
    ]),
    displayDescription (val) {
      if (typeof val === 'string' || val instanceof String) {
        const splitItem = val.split('_')
        const arr = []
        splitItem.forEach(item => {
          const lowercase = item.toLowerCase()
          const caps = lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
          arr.push(caps)
        })
        const str = arr.slice(0, arr.length).join(' ')
        return str
      }
    },
    showDelete (key, val) {
      this.basic = val
      this.currentKey = key
      this.isDelete = true
    },
    closeDelete  () {
      this.basic = {
        description: '',
        type: {},
        total: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    deleteItem () {
      this.deleteGeneralItem(this.currentKey)
      this.basic = {
        description: '',
        type: {},
        total: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    cancel () {
      this.basic = {
        description: '',
        type: {},
        total: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    editIndividual (key, val) {
      this.isEdit = true
      this.basicLiabilityTypes.forEach((item) => {
        if (item.value === val.zakaatBasicLiabilityType) {
          this.basic.type = item
        }
      })
      this.basic.description = val.description
      this.basic.total = val.amount

      this.currentKey = key
    },
    async update () {
      const saveBasic = {
        key: '',
        item: {
          description: '',
          amount: 0,
          zakaatBasicLiabilityType: ''
        }
      }
      saveBasic.item.zakaatBasicLiabilityType = this.basic.type.value
      saveBasic.item.description = this.basic.description
      saveBasic.item.amount = this.basic.total
      saveBasic.key = this.currentKey
      this.editGeneral(saveBasic)
      this.basic = {
        description: '',
        type: {},
        total: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    async save () {
      const saveBasic = {
        description: '',
        amount: 0,
        zakaatBasicLiabilityType: ''
      }
      saveBasic.zakaatBasicLiabilityType = this.basic.type.value
      saveBasic.description = this.basic.description
      saveBasic.amount = this.basic.total
      this.addToGeneral(saveBasic)
      this.basic = {
        description: '',
        type: {},
        total: null
      }
    }
  }
}
</script>

<style scoped>
.smallheading {
  color: var(--green-color);
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
